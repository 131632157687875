import * as React from "react";
import classnames from "classnames";
import useWidth from "msem-lib/es/hooks/use-width.js";
import useAvailableHeight from "../../hooks/use-available-height";
import useWidgetContext, { WidgetContext, defaultScreenSizes } from "msem-lib/es/widget/widget-context";
import WidgeTitlebar from "./widget-titlebar";
import WidgetError from "./widget-error";
import Dialog from "./dialog";
import { target, targets } from "msem-lib/es/services/target";
import { formatDate, datesEqual } from "../../services/date";
import * as Stay from "../../services/stay";
import * as Facet from "../../services/facet";
import configuration from "../../services/config";
import DatePicker from "../stay/date-picker";
import PaxplanPicker from "../stay/paxplan-picker";
import css from "./widget.module.css";
import I18n from "msem-lib/es/components/i18n";
const LodgingPicker = React.lazy(() => import("../stay/lodging-picker"));

const { featureLocation: API_URL } = configuration();

const Position = (position) => position.split(",").map(Number);

const fetchResort = async (code) => {
  const url = `${API_URL}/map-config?r=${code}`;
  const res = await fetch(url);
  const data = await res.json();
  const { id, libelle, position, search, radius, zoom } = data;
  return {
    code: id,
    name: libelle,
    position: Position(position),
    search: Position(search),
    radius,
    zoom,
  };
};

const useResort = (code) => {
  const [resort, setResort] = React.useState();
  React.useEffect(() => {
    //  CAS ACTIBUS
    if (code === undefined) {
      setResort({ code: null });
      return;
    }
    fetchResort(code)
      .then((data) => setResort(data))
      .catch((_) => {
        throw new Error(`Invalid resort code : ${code}`);
      });
  }, [code]);
  return resort;
};

const Viewport = ({
  minHeight,
  grounded,
  verticalMargin,
  fullWidth,
  maxWidth,
  noTitlebar,
  titleBarShadow,
  dialogOpened,
  dialogMaxWidth,
  dialogContent,
  dialogScrollableRef,
  screenScrollableRef,
  close,
  goBackHandler,
  environments,
  environmentChanged,
  children,
}) => {
  const { options, closeDialog } = useWidgetContext();
  const height = useAvailableHeight({ minHeight, grounded, verticalMargin });
  const widgetRef = React.useRef(null);
  const backdropClasses = classnames(css.backdrop, {
    [css.grounded]: grounded,
  });
  const widgetClasses = classnames("msem-widget-root", css.widget, {
    [css.fullWidth]: fullWidth,
    grounded: grounded,
  });

  let cursorStartPosX, cursorStartPosY, widgetX, widgetY;

  const onStartDrag = (x, y) => {
    cursorStartPosX = x;
    cursorStartPosY = y;
    widgetX = Number(widgetRef.current.style.left.replace("px", ""));
    widgetY = Number(widgetRef.current.style.top.replace("px", ""));
  };

  const onDrag = (e) => {
    const { draggable } = options;
    if (!draggable) return;
    const deltaX = Math.max(0, e.clientX) - cursorStartPosX;
    const deltaY = Math.max(0, e.clientY) - cursorStartPosY;
    if (widgetRef?.current) {
      widgetRef.current.style.left = `${widgetX + deltaX}px`;
      widgetRef.current.style.top = `${widgetY + deltaY}px`;
    }
  };

  return (
    <div className={backdropClasses}>
      <div ref={widgetRef} className={widgetClasses} style={{ height, maxWidth }}>
        <WidgeTitlebar
          channel={options.channel}
          close={close}
          goBack={goBackHandler}
          noTitlebar={noTitlebar}
          titleBarShadow={titleBarShadow}
          environments={environments}
          environmentChanged={environmentChanged}
          onDrag={onDrag}
          onStartDrag={onStartDrag}
          lang={options.lang}
          // onStopDrag={onStopDrag}
        />
        <div className={css.viewport} id={"viewport"}>
          <Dialog
            opened={dialogOpened}
            scrollableRef={dialogScrollableRef}
            maxWidth={dialogMaxWidth}
            grounded={grounded}
            close={closeDialog}
          >
            <WidgetError>{dialogContent}</WidgetError>
          </Dialog>
          <div ref={screenScrollableRef} className={css.screens}>
            <WidgetError>{children}</WidgetError>
          </div>
        </div>
      </div>
    </div>
  );
};

const SCREEN_SIZES = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

function getScreenSizes(width) {
  return {
    XS: true,
    SM: width >= SCREEN_SIZES.SM,
    MD: width >= SCREEN_SIZES.MD,
    LG: width >= SCREEN_SIZES.LG,
    XL: width >= SCREEN_SIZES.XL,
    XXL: width >= SCREEN_SIZES.XXL,
  };
}

const Widget = ({ options, presets, minHeight, close, refreshTunnel, config, children }) => {
  const {
    stayChanged,
    isGescoOperator,
    noTitlebar = false,
    groundedTo,
    fullWidth,
    verticalMargin = 100,
    maxWidth,
    lang,
    cartId: optionsCartId,
    titleBarShadow,
  } = options;
  const datesPreset = presets.stay ? Stay.deserializeDates(presets.stay) : undefined;
  const presetLodging = presets.lodging && presets.lodging.name ? presets.lodging : undefined;
  const presetPaxplan = presets.paxplan
    ? presets.paxplan
    : Stay.readPaxplan() || { adults: 2, children: 0, agesChildren: [] };
  const grounded = groundedTo !== undefined;
  const targetNoTitlebar = noTitlebar || grounded;
  const dialogScrollableRef = React.useRef();
  const screenScrollableRef = React.useRef();
  const [dates, setDates] = React.useState(datesPreset);
  const [cartId, setCartId] = React.useState();
  const [lodging, setLodging] = React.useState(presetLodging);
  const [paxplan, setPaxplan] = React.useState(presetPaxplan);
  const [dialogOpened, setDialogOpened] = React.useState(false);
  const [dialogMaxWidth, setDialogMaxWidth] = React.useState();
  const [dialogContent, setDialogContent] = React.useState();
  const [goBackHandler, setGoBackHandler] = React.useState();
  const [environment, setEnvironment] = React.useState(target());
  const resort = useResort(options.resort);
  const width = useWidth(screenScrollableRef);
  const [screenSizes, setScreenSizes] = React.useState(defaultScreenSizes);

  React.useEffect(() => {
    if (width > 0) setScreenSizes(getScreenSizes(width));
  }, [width]);

  const environments = targets();
  const environmentChanged = (e) => {
    const newEnvironment = e.target.value;
    window.sessionStorage.setItem("MseM-environment", newEnvironment);
    setEnvironment(newEnvironment);
  };

  React.useEffect(() => {
    const prevResort = Stay.readResort();
    // Si la station courante est différente de celle du storage
    // => On fait le ménage dans le session storage
    if (!resort || !prevResort || prevResort.code !== resort.code) {
      Stay.writeResort(resort);
      const cartId = Stay.syncCartId(resort?.code, prevResort?.code);
      setCartId(cartId);
    }

    if (prevResort && resort && prevResort.code !== resort.code) {
      Stay.removeLodging();
    }

    if (resort) {
      const prevLodging = Stay.readLodging();
      // Si on a pas de lieu de résidence dans le state,
      // => on l'initialise avec les infos du session storage
      if (!lodging) {
        setLodging(prevLodging);
      }
      // Si le lieu de résidence du state n'est plus le même que celui du storage
      // => On fait le ménage dans le session storage
      else if (!prevLodging || prevLodging.name !== lodging.name) {
        Stay.writeLodging(lodging);
      }
    }

    // Surgarger la facet si on a des dates de bascules de de saisons
    if (options.switchSummerDate && options.switchWinterDate) {
      Facet.overloadFacetOption(options, dates?.from);
    }

    const prevDates = Stay.readDates();
    // Si on a pas de dates de séjour dans le state
    // => on les initialise avec les infos du storage
    if (!dates) {
      setDates(prevDates);
    }
    // Si les dates de séjour du state ne sont plus le mêmes que celles du storage
    // => On fait le ménage dans le session storage
    else if (!prevDates || !datesEqual(prevDates, dates)) {
      const resortCode = resort ? resort.code : undefined;
      const stayChangedDefined = stayChanged !== undefined && typeof stayChanged === "function";
      Stay.writeDates(dates, resortCode, !stayChangedDefined && !optionsCartId);
      if (stayChangedDefined)
        stayChanged(dates); // pas de suppression du panier dans ce cas, on laisse l'intégrateur gérer
      else if (resort !== undefined && !optionsCartId) Stay.removeCartId(resort.code);
      const event = { event: "stayChanged", from: Stay.serializeDate(dates.from), to: Stay.serializeDate(dates.to) };
      options.analytics && options.analytics(event);
    }

    const prevPaxplan = Stay.readPaxplan();
    // Si on a pas de paxplan dans le state
    // => on l'initialise avec les infos du storage
    if (!paxplan) {
      setPaxplan(prevPaxplan);
    }
    // Si le paxplan du state n'est plus le même que celles du storage
    // => On fait le ménage dans le session storage
    else if (!prevPaxplan || !Stay.paxplansEqual(prevPaxplan, paxplan)) {
      Stay.writePaxplan(paxplan);
      if (resort !== undefined) Stay.removeCartId(resort.code);
    }
  }, [resort, dates, lodging, paxplan, options, stayChanged, optionsCartId]);

  const setGoBack = React.useCallback((handler) => {
    setGoBackHandler(() => handler);
  }, []);

  const openDialog = React.useCallback((content, maxWidth) => {
    setDialogContent(content);
    setDialogMaxWidth(maxWidth);
    setDialogOpened(true);
  }, []);

  const closeDialog = React.useCallback(() => {
    setDialogOpened(false);
    setTimeout(setDialogContent, 240);
  }, []);

  const datesChanged = React.useCallback(
    ({ start, stop, keepCartId }) => {
      const dates = { from: start, to: stop };
      if (stayChanged && typeof stayChanged === "function") stayChanged(dates);
      else if (!keepCartId) Stay.removeCartId(resort.code);
      if (options.switchSummerDate && options.switchWinterDate) {
        Facet.overloadFacetOption(options, dates?.from);
      }
      setDates(dates);
      return dates;
    },
    [resort, options, stayChanged]
  );

  const lodgingChanged = (lodging) => {
    setLodging(lodging);
    return lodging;
  };

  const paxplanChanged = React.useCallback(
    ({ adults, children, agesChildren }) => {
      const paxplan = { adults, children, agesChildren };
      Stay.removeCartId(resort.code);
      setPaxplan(paxplan);
      return paxplan;
    },
    [resort]
  );

  React.useEffect(() => {
    if (options.cartId && resort) {
      const cartIds = Stay.readCartByResorts();
      Stay.writeCartByResorts({ ...cartIds, [resort.code]: options.cartId });
      window.sessionStorage.setItem("cartId", JSON.stringify(options.cartId));
    }
  }, [resort, options.cartId]);

  const openStayDatesPicker = React.useCallback(
    (callback = datesChanged) => {
      openDialog(
        <DatePicker
          selected={dates ? { start: dates.from, stop: dates.to } : undefined}
          scrollableRef={dialogScrollableRef}
          close={closeDialog}
          from={Stay.getStartDate(options.preview, options.isGescoOperator)}
          to={Stay.getEndDate(options.preview, options.isGescoOperator)}
          selectionChanged={callback}
          rangePicker
          extendableStay={options.extendableStay}
          presetedDates={datesPreset}
          B2B={options.B2B}
          isGescoOperator={isGescoOperator}
        />,
        400
      );
    },
    [
      datesChanged,
      openDialog,
      dates,
      closeDialog,
      options.preview,
      options.extendableStay,
      datesPreset,
      options.B2B,
      isGescoOperator,
    ]
  );

  const openStayLodgingPicker = React.useCallback(
    (callback = lodgingChanged) => {
      openDialog(
        <LodgingPicker
          resort={resort}
          close={closeDialog}
          lodgingChanged={callback}
          lodging={lodging}
          facet={options.facet}
        />
      );
    },
    [closeDialog, lodging, openDialog, resort, options.facet]
  );

  const openStayPaxplanPicker = React.useCallback(
    (callback = paxplanChanged) => {
      openDialog(<PaxplanPicker close={closeDialog} paxplanChanged={callback} paxplan={paxplan} />, 452);
    },
    [paxplanChanged, closeDialog, paxplan, openDialog]
  );

  const getStayDates = React.useCallback(
    ({ force = false } = {}) => {
      return new Promise((resolve) => {
        if (dates || !force) return resolve(dates);
        openStayDatesPicker((result) => resolve(datesChanged(result)));
      });
    },
    [dates, openStayDatesPicker, datesChanged]
  );

  const getStayLodging = React.useCallback(
    ({ force = false } = {}) => {
      return new Promise((resolve) => {
        if (lodging || !force) return resolve(lodging);
        openStayLodgingPicker((result) => resolve(lodgingChanged(result)));
      });
    },
    [openStayLodgingPicker, lodging]
  );

  const getStayPaxplan = React.useCallback(
    ({ force = false } = {}) => {
      return new Promise((resolve) => {
        if (paxplan || !force) return resolve(paxplan);
        openStayPaxplanPicker((result) => resolve(paxplanChanged(result)));
      });
    },
    [paxplan, openStayPaxplanPicker, paxplanChanged]
  );

  const analyticsCallback = (analytics) => async (data) => {
    if (typeof analytics === "function") {
      await analytics(data);
    }
  };

  const stay = React.useMemo(() => ({ resort, dates, lodging }), [dates, lodging, resort]);

  const buildTreeKey = (config) => {
    const { refreshOnLodgingChange, keyExclusions } = config;
    const where = refreshOnLodgingChange && lodging ? lodging.name : "";
    const dateKey =
      dates && !keyExclusions?.includes("stay") ? `${formatDate(dates.from)}-${formatDate(dates.to)}` : "";
    const resortKey = resort ? resort.code : "";
    const key = `${resortKey}-${dateKey}-${where}-${environment}`;
    const ready = resort !== undefined;
    return { key, ready };
  };

  const { key, ready } = buildTreeKey(config);
  if (!ready) return null;

  return (
    <I18n lang={lang} widget={"msem-widget"}>
      <WidgetContext.Provider
        value={{
          options,
          presets,
          stay,
          paxplan,
          cartId,
          setGoBack,
          openDialog,
          closeDialog,
          close,
          refreshTunnel,
          setStayDates: datesChanged,
          getStayDates,
          getStayLodging,
          analytics: analyticsCallback(options.analytics),
          setStayLodging: lodgingChanged,
          getStayPaxplan,
          setStayPaxplan: paxplanChanged,
          openStayDatesPicker,
          openStayLodgingPicker,
          openStayPaxplanPicker,
          dialogScrollableRef,
          screenScrollableRef,
          screenSizes,
        }}
      >
        <Viewport
          key={key}
          minHeight={minHeight}
          grounded={grounded}
          verticalMargin={verticalMargin}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          noTitlebar={targetNoTitlebar}
          titleBarShadow={titleBarShadow}
          dialogOpened={dialogOpened}
          dialogMaxWidth={dialogMaxWidth}
          dialogContent={dialogContent}
          dialogScrollableRef={dialogScrollableRef}
          screenScrollableRef={screenScrollableRef}
          close={close}
          goBackHandler={goBackHandler}
          environments={environments}
          environmentChanged={environmentChanged}
        >
          {children}
        </Viewport>
      </WidgetContext.Provider>
    </I18n>
  );
};

export default Widget;
